export const initFunnelParams = () => {
    // VARS
    const links = document.querySelectorAll('a');
    if (!links.length) return;
    const search = window.location.search;

    // INITS
    links.forEach((link) => {
        if (!link.href.includes('mailto:') && !link.href.includes('tel:')) {
            link.href = `${link.href}${search}`;
        }
    });
};
